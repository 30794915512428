@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    text-transform: uppercase;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1650);
    padding: 0 rem(10);
}

#page-wrap{
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.social{
    margin: 0;
    padding: 0;
    li{
        display: inline-block;
        &+li{
            margin-left: .5em;
        }
    }
}

#header{
    padding: rem(15) 0 0;
    .container{
        display: flex;
        justify-content: space-between;
        h1{
            margin: 0;
            padding: 0;
            line-height: 1;
            img{
                display: block;
            }
        }
        @media screen and (max-width: 900px){
            justify-content: center;
        }
    }
}

#main{
    padding: rem(50) 0;
    @media screen and (max-width: 900px){
        padding: rem(20) 0;
    }
    img{
        display: block;
    }
    .container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media screen and (max-width: 900px){
            flex-direction: column;
        }
    }

    #maincontent{
        width: 100%;
        max-width: rem(500);
        text-align: center;
        padding: 0 rem(10);
        p{
            font-size: rem(26);
            text-transform: uppercase;
            @media screen and (max-width: 900px){
                font-size: rem(18);
            }
            &:nth-child(2){
                color: $primary-color;
            }
        }
        .note{
            font-size: rem(8);
        }

        input{
            display: block;
            border: none;
            border-bottom: 2px solid $primary-color;
            font-size: rem(18);
            width: 100%;
            padding: rem(5);
            margin-bottom: rem(25);
            background: none;
            text-transform: uppercase;
            color: $white;
            text-align: center;
            @media screen and (max-width: 900px){
                font-size: rem(14);
            }
            &:focus{
                border-bottom-color: $white;
            }
            &::placeholder{
                color: $white;
            }
        }

        button, .btn{
            display: inline-block;
            margin: rem(40) 0 0;
            text-decoration: none;
            border-radius: 0;
            border: 2px solid $primary-color;
            background-color: $black;
            color: $primary-color;
            font-size: rem(22);
            padding: rem(10);
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            transition: .3s all;
            @media screen and (max-width: 900px){
                font-size: rem(18);
            }
            @include hover{
                color: $black;
                background-color: $primary-color;
            }
        }
    }
    
}


#footer{
    .newsletter{
        input{
            border: none;
            border-bottom: 1px solid $white;
            font-size: rem(9);
            color: $white;
            background: none;
            text-transform: uppercase;
            padding: rem(4) 0 rem(3);
            &::placeholder{
                color: $white;
            }
        }
        button{
            border-radius: 0;
            background: none;
            border: 1px solid $white;
            font-size: rem(9);
            padding: rem(3);
            color: $white;
            text-transform: uppercase;
        }
    }
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 900px){
            flex-direction: column;
            text-align: center;
            .social{
                margin-top: rem(10);
            }
        }
        .copyright{
            font-family: sans-serif;
            width: rem(400);
            max-width: 100%;
            font-size: rem(8);
            text-transform: uppercase;
            text-align: right;
            @media screen and (max-width: 900px){
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 900px){
    .desktop-only{
        display: none !important;
    }
}

@media screen and (min-width: 901px){
    .mobile-only{
        display: none !important;
    }
}